import React, { Children, useEffect, useState } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import PageClass from '../menu/level/pageClass'
import ViewLevel from '../../modules/level/ViewLevel'
import ViewRole from '../../modules/role/ViewRole'
import ViewKelas from '../../modules/Kelas/ViewKelas'
import ViewUserUjian from '../../modules/UserUjian/ViewUserUjian'
import ViewUserLatihan from '../../modules/UserLatihan/ViewUserLatihan';
import ContainerUser from '../../modules/Assign_User/ContainerUser'
import ViewLevelProduks from '../../modules/levelProduk/ViewLevelProduks'
import { FontIcon, List, ListItem } from 'react-md'
import ContainerSoal from '../../modules/Soal/ContainerSoal'
import ViewTypeSoal from '../../modules/Typesoal/ViewTypeSoal'
import ViewMateri from '../../modules/Materi_Pembelajaran/ViewMateri'
import ViewProduct from '../../modules/product/ViewProduct'
import ViewGrade from '../../modules/Grade/ViewGrade'

// let companyId = sessionStorage.getItem("companyId")

const LevelProduct = ({ history, ...props }) => {
    let role = localStorage.getItem("getRole")
    let companyId = props.match.params.companyId
    let levelId = props.match.params.levelId
    let productId = props.match.params.productId
    let chooseClass

    if (role === "ROLE_ADMIN") {
        chooseClass = [
            {
                label: "Pilih Kelas",
                children: [
                    {
                        label: "Choose Class",
                        index: "choose, choose",
                        resourceUri: "",
                        iconClassName: "mdi mdi mdi-view-dashboard",
                        path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`,
                        childPath: [],
                    },
                ],
            },
            // {
            //     label: "Materi Pembelajaran",
            //     children: [
            //         {
            //             label: "Materi Pembelajaran",
            //             index: "materi-pembelajaran, materi-pembelajaran",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/materi-pembelajaran`,
            //             childPath: [],
            //         },
            //     ],
            // },
            // {
            //     label: "Soal",
            //     children: [
            //         {
            //             label: "Soal",
            //             index: "soal, soal",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/exam`,
            //             childPath: [],
            //         },
            //         {
            //             label: "Type Soal",
            //             index: "tipeSoal, tipeSoal",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/type-soal`,
            //             childPath: [],
            //         },
            //     ],
            // },
            // {
            //     label: "Kelas",
            //     children: [
            //         {
            //             label: "Kelas",
            //             index: "kelas, kelas",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/kelas`,
            //             childPath: [],
            //         },
            //     ],
            // },
            // {
            //     label: "Lain-Lain",
            //     children: [
            //         {
            //             label: "Assign User",
            //             index: "assign, assign",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-account-box-multiple-outline",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/assign-user`,
            //             childPath: []
            //         },
            //         {
            //             label: "Level",
            //             index: "level, level",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/level`,
            //             childPath: []
            //         },
            //         {
            //             label: "Level Produk",
            //             index: "levelProduk, levelProduk",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/level-produk`,
            //             childPath: []
            //         },
            //         {
            //             label: "Grade",
            //             index: "grade, grade",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/grade`,
            //             childPath: []
            //         },
            //         {
            //             label: "Product",
            //             index: "product, product",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/product`,
            //             childPath: []
            //         },
            //         {
            //             label: "Role",
            //             index: "role, role",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-account-convert-outline",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/role`,
            //             childPath: []
            //         },
            //     ]
            // },
        ]
    }
    else if (role === "ROLE_PENGAJAR") {
        chooseClass = [
            {
                label: "Pilih Kelas",
                children: [
                    {
                        label: "Choose Class",
                        index: "choose, choose",
                        resourceUri: "",
                        iconClassName: "mdi mdi mdi-view-dashboard",
                        path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`,
                        childPath: [],
                    },
                ],
            },
            // {
            //     label: "Materi Pembelajaran",
            //     children: [
            //         {
            //             label: "Materi Pembelajaran",
            //             index: "materi-pembelajaran, materi-pembelajaran",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/materi-pembelajaran`,
            //             childPath: [],
            //         },
            //     ],
            // },
            // {
            //     label: "Soal",
            //     children: [
            //         {
            //             label: "Soal",
            //             index: "soal, soal",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/exam`,
            //             childPath: [],
            //         },
            //         {
            //             label: "Type Soal",
            //             index: "tipeSoal, tipeSoal",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/type-soal`,
            //             childPath: [],
            //         },
            //     ],
            // },
            // {
            //     label: "Kelas",
            //     children: [
            //         {
            //             label: "Kelas",
            //             index: "kelas, kelas",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/kelas`,
            //             childPath: [],
            //         },
            //     ],
            // },
            // {
            //     label: "Lain-Lain",
            //     children: [
            //         {
            //             label: "Assign User",
            //             index: "assign, assign",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-account-box-multiple-outline",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/assign-user`,
            //             childPath: []
            //         },
            //         {
            //             label: "Level",
            //             index: "level, level",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/level`,
            //             childPath: []
            //         },
            //         {
            //             label: "Level Produk",
            //             index: "levelProduk, levelProduk",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/level-produk`,
            //             childPath: []
            //         },
            //         {
            //             label: "Product",
            //             index: "product, product",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-table",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/product`,
            //             childPath: []
            //         },
            //         {
            //             label: "Role",
            //             index: "role, role",
            //             resourceUri: "",
            //             iconClassName: "mdi mdi-account-convert-outline",
            //             path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/role`,
            //             childPath: []
            //         },
            //     ]
            // },
        ]
    }
    else {
        chooseClass = [
            {
                label: "Pilih Kelas",
                children: [
                    {
                        label: "Choose Class",
                        index: "choose, choose",
                        resourceUri: "",
                        iconClassName: "mdi mdi mdi-view-dashboard",
                        path: `/product/company/${companyId}/choose-level/${levelId}/choose-product/${productId}/choose-class`,
                        childPath: [],
                    },
                ],
            }
        ]
    }
    return (
        <PageWrapper
            sidebarCollapsible={true}
            inverseTheme={true}
            // useSidebar={false}
            // sidebarMenu={chooseKelas}
            sidebarMenu={chooseClass}
            sidebarHeader={(
                <>
                    <List style={{ width: '100%', background: 'white', padding: '0px', height: '65px' }}>
                        <ListItem style={{ marginTop: '-3px' }} secondaryText={`Kembali`} rightAddon={<FontIcon onClick={() => {
                            window.location.href = `/product/company/${companyId}/choose-level/${levelId}/choose-product`
                        }} iconClassName="mdi mdi-arrow-left"></FontIcon>}>
                        </ListItem>
                    </List>
                </>
            )}

        >
            <Router history={history}>
                <Switch>
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/choose-class'
                        exact={true}
                        render={props => (
                            <PageClass {...props} />
                        )}
                    />

                    {/* Materi */}
                    <Route
                        path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/materi-pembelajaran"
                        exact={true}
                        render={(props) => <ViewMateri {...props} />}
                    />

                    {/* Soal */}
                    <Route
                        path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/exam"
                        exact={true}
                        render={(props) => <ContainerSoal {...props} />}
                    />

                    <Route
                        path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/type-soal"
                        exact={true}
                        render={(props) => <ViewTypeSoal {...props} />}
                    />

                    {/* Kelas */}
                    <Route
                        path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/kelas"
                        exact={true}
                        render={(props) => <ViewKelas {...props} />}
                    />

                    {/* Lainnya */}

                    {/* Assign User */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/assign-user'
                        exact={true}
                        render={props => (
                            <ContainerUser {...props} />
                        )}
                    />

                    {/* User Latihan */}
                    {/* <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/user-exercise'
                        exact={true}
                        render={props => (
                            <ViewUserLatihan {...props} />
                        )}
                    /> */}

                    {/* User Ujian */}
                    {/* <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/user-exam'
                        exact={true}
                        render={props => (
                            <ViewUserUjian {...props} />
                        )}
                    /> */}

                    {/* Kelas */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/kelas'
                        exact={true}
                        render={props => (
                            <ViewKelas {...props} />
                        )}
                    />

                    {/* ROLE */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/role'
                        exact={true}
                        render={props => (
                            <ViewRole {...props} />
                        )}
                    />

                    {/* Produk */}
                    <Route
                        path="/product/company/:companyId/choose-level/:levelId/choose-product/:productId/product"
                        exact={true}
                        render={(props) => <ViewProduct {...props} />}
                    />

                    {/* LEVEL */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/level'
                        exact={true}
                        render={props => (
                            <ViewLevel {...props} />
                        )}
                    />

                    {/* LEVEL PRODUCT */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/level-produk'
                        exact={true}
                        render={props => (
                            <ViewLevelProduks {...props} />
                        )}
                    />

                    {/* GRADE */}
                    <Route
                        path='/product/company/:companyId/choose-level/:levelId/choose-product/:productId/grade'
                        exact={true}
                        render={props => (
                            <ViewGrade {...props} />
                        )}
                    />
                </Switch>
            </Router>
        </PageWrapper>
    )
}

export default sso({
    basePath: '/product/company/:companyId',
    url: {
        me: `/api/sso/company/:companyId/me`
    }
})(LevelProduct)
